<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="!list"
        elevation="0"
        block
        height="30px"
        :disabled="disabled"
        @click="open"
        v-text="$t('edit', { name: '' })"
      />
    </template>
    <v-card>
      <v-card-title>
        {{ $t("limitation", { name: $tc("artist") }) }}
      </v-card-title>
      <v-card-subtitle class="subtitle_text">
        {{ $t("limitation_explanation") }}
      </v-card-subtitle>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row align="center">
          <v-col cols="12" md="10" class="py-0">
            {{ $t("limitations.appointment_new") }}
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <v-select
              hide-details
              :items="yesNo"
              v-model="configuration.appointment_new"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" md="10" class="py-0">
            {{ $t("limitations.appointment_edit") }}
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <v-select
              hide-details
              :items="yesNo"
              v-model="configuration.appointment_edit"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" md="10" class="py-0">
            {{ $t("limitations.box") }}
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <v-select
              hide-details
              :items="yesNo"
              v-model="configuration.box"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" md="10" class="py-0">
            {{ $t("limitations.customer_edit") }}
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <v-select
              hide-details
              :items="yesNo"
              v-model="configuration.customer_edit"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          @click="dialog = false"
          outlined
          style="width: 100px !important; height: 30px"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          style="
            width: 100px !important;
            color: #363533;
            height: 30px;
            padding-top: 10px !important;
          "
          elevation="0"
          @click="save"
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: "" }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TattooerLimitations",
  props: ["tattooer", "list"],
  data() {
    return {
      dialog: false,
      yesNo: [
        { text: this.$t("yes"), value: true },
        { text: this.$t("no"), value: false },
      ],
      configuration: {
        appointment_new: true,
        appointment_edit: true,
        box: true,
        customer_edit: true,
      },
    };
  },
  methods: {
    open() {
      this.dialog = true;
      console.log(this.tattooer.tattooer_studios);
      if (this.tattooer.tattooer_studios[0].limitations) {
        this.configuration = this.tattooer.tattooer_studios[0].limitations;
      }
    },
    ...mapActions("tattooers", ["setLimitations"]),
    save() {
      console.log(this.configuration);
      this.setLimitations({
        tattooer_id: this.tattooer.id,
        studio_id: this.$store.state.auth.user.studio.id,
        limitations: this.configuration,
      }).then((response) => {
        if (response.success) {
          this.dialog = false;
          this.$alert(this.$t("save_ok"));
          this.$emit("update");
        } else {
        }
      });
    },
  },
};
</script>
